import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { UserService } from '../api/user.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MyAuthService {
  isAuthenticated$ = this.authService.isAuthenticated$;
  rol: number = 0;
  rolChange: Subject<number> = new Subject<number>();

  constructor(
    private authService: AuthService,
    @Inject(DOCUMENT) private doc: Document,
    private userService: UserService
  ) {
    this.rolChange.subscribe((value) => {
      this.rol = value;
    });
  }

  login(url: string) {
    this.authService.loginWithRedirect({
      appState: { target: url },
    });
  }

  logOut() {
    this.authService.logout({
      logoutParams: {
        returnTo: this.doc.location.origin,
      },
    });
    this.setRol(0);
  }

  getRol() {
    this.userService.postLogin().then((x) => {
      this.setRol(x.rol);
    });
  }

  setRol(x: number) {
    return this.rolChange.next(x);
  }
}
