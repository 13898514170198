export const ENDPOINTS = {
  //Usuario
  USUARIO: 'user',
  USUARIO_DIRECCION: 'user/direccion',
  USUARIO_ELIMINARDIRECCION: 'user/deleteDireccion',
  USUARIO_LOGIN: 'user/login',

  //Planes
  PLAN: '',
  PLAN_ALL: 'plan/all',

  //Menu
  MENU: '',
  MENU_ALL: 'menu/all',
  MENU_COMIDA: 'menu/comidas',

  //PEDIDO
  PEDIDO: 'user/pedido',
  PEDIDO_ALL: 'admin/pedidos',
  COMIDA_ALL: 'admin/comidas',
  UPDATE_ESTADO: 'admin/updateEstadoPedido',

  //REDE
  TOKEN: 'tokenization',

  //CUPON
  CUPON: 'validarCupon',

  //CEP
  CEP: 'cep',
};
