import { Injectable } from '@angular/core';
import { ResumenCompra } from '../../models/resumen-compra';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  setResumen(resumen: ResumenCompra) {
    localStorage.setItem('resumen_compra', JSON.stringify(resumen));
  }

  clearResumen() {
    localStorage.removeItem('resumen_compra');
  }

  clearAll() {
    this.clearResumen();
  }
}
