import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './components/pages/error/error.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./components/pages/home/home.module').then((m) => m.HomeModule),
  },

  {
    path: 'compra',
    loadChildren: () =>
      import('./components/pages/compra/compra.module').then(
        (m) => m.CompraModule
      ),
  },

  {
    path: 'preg-frecuentes',
    loadChildren: () =>
      import('./components/pages/preg-frecuentes/preg-frecuentes.module').then(
        (m) => m.PregFrecuentesModule
      ),
  },
  {
    path: 'politica-privacidad',
    loadChildren: () =>
      import(
        './components/pages/politicas-privacidad/politicas-privacidad.module'
      ).then((m) => m.PoliticasPrivacidadModule),
  },
  {
    path: 'nossas-lojas',
    loadChildren: () =>
      import('./components/pages/nossas-lojas/nossas-lojas.module').then(
        (m) => m.NossasLojasModule
      ),
  },
  {
    path: 'linea',
    loadChildren: () =>
      import('./components/pages/linea/linea.module').then(
        (m) => m.LineaModule
      ),
  },
  {
    path: 'meu-perfil',
    loadChildren: () =>
      import('./components/pages/mi-perfil/mi-perfil.module').then(
        (m) => m.MiPerfilModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./components/pages/admin/admin.module').then(
        (m) => m.AdminModule
      ),
  },
  {
    path: 'callback',
    loadChildren: () =>
      import('./components/pages/login/login.module').then(
        (m) => m.LoginModule
      ),
  },

  { path: '**', component: ErrorComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 100], // cool option, or ideal option when you have a fixed header on top.
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
