import { Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  navSidebarClass: boolean = true;
  totalAllContactLength: any = 0;
  totalPendingContactLength: any = 0;
  showSpinner: boolean = false;
  showSpinnerChange: Subject<boolean> = new Subject<boolean>();
  rol: number = 0;
  rolChange: Subject<number> = new Subject<number>();

  constructor() {
    this.showSpinnerChange.subscribe((value) => {
      this.showSpinner = value;
    });

    this.rolChange.subscribe((value) => {
      this.rol = value;
    });
  }

  toggleSpinner(_displaySpinner: boolean) {
    this.showSpinnerChange.next(_displaySpinner);
  }

  toggleRol(x: number) {
    return this.rolChange.next(x);
  }
}
