import { Injectable } from '@angular/core';
import { ApiService, ApiGenericService } from './api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '../../constants/endpoints';
import { ENVIRONMENT } from 'src/app/environments/environment';
import { Domicilio } from '../../models/domicilio';
import { Usuario } from '../../models/usuario';

@Injectable({ providedIn: 'root' })
export class UserService extends ApiGenericService {
  constructor(private httpClient: HttpClient, private apiService: ApiService) {
    super(httpClient, ENVIRONMENT.API_URL, ENDPOINTS.USUARIO);
  }

  async guardarUsuario(usuario: Usuario): Promise<any> {
    return await this.apiService
      .put<any>(ENVIRONMENT.API_URL, ENDPOINTS.USUARIO, usuario)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return null;
        })
      )
      .toPromise();
  }

  async obtenerDireccion(): Promise<any> {
    return await this.apiService
      .get<any>(ENVIRONMENT.API_URL, ENDPOINTS.USUARIO_DIRECCION)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return null;
        })
      )
      .toPromise();
  }
  async guardarDireccion(direccion: Domicilio): Promise<any> {
    return await this.apiService
      .post<any>(ENVIRONMENT.API_URL, ENDPOINTS.USUARIO_DIRECCION, direccion)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return null;
        })
      )
      .toPromise();
  }

  async eliminarDireccion(id: number): Promise<any> {
    let objeto = {
      id: id,
    };
    return await this.apiService
      .put<any>(
        ENVIRONMENT.API_URL,
        ENDPOINTS.USUARIO_ELIMINARDIRECCION,
        objeto
      )
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return null;
        })
      )
      .toPromise();
  }

  async postLogin(): Promise<any> {
    return await this.apiService
      .get<any>(ENVIRONMENT.API_URL, ENDPOINTS.USUARIO_LOGIN)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return null;
        })
      )
      .toPromise();
  }
  async obtenerInfoCEP(cep): Promise<any> {
    return await this.httpClient
      .get<any>('https://viacep.com.br/ws/' + cep + '/json/')
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return null;
        })
      )
      .toPromise();
  }
}
