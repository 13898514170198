const API_URL = 'https://api-dev.freshandfit.com.br/public/api/';
export const ENVIRONMENT = {
  DEVELOPMENT: true,
  TESTING: false,
  PRODUCTION: false,
  API_URL: API_URL,
  API_REDE: 'https://rl7-sandbox-api.useredecloud.com.br/v1/',
  TOKEN_REDE: 'OTk3MTQwNzI6NWMzYzEwY2NiZDBlNGYxODg5NDFjZDY0YzQxNzYzY2M',
  AUTH: {
    domain: 'msmoonreallfood-dev.us.auth0.com',
    clientId: 'N3cFTJqP7uya2F61Sv4Qu1Dp83m6ILBP',
    authorizationParams: {
      redirect_uri: window.location.origin + '/callback',
      // Request this audience at user authentication time
      audience: API_URL,

      // Request this scope at user authentication time
      // scope: 'read:current_user',
    },

    // Specify configuration for the interceptor
    httpInterceptor: {
      allowedList: [
        {
          // Match any request that starts 'https://{yourDomain}/api/v2/' (note the asterisk)
          uri: API_URL + 'user*',
          tokenOptions: {
            authorizationParams: {
              // The attached token should target this audience
              audience: API_URL,

              // The attached token should have these scopes
              //  scope: 'read:current_user',
            },
          },
        },
        {
          // Match any request that starts 'https://{yourDomain}/api/v2/' (note the asterisk)
          uri: API_URL + 'admin*',
          tokenOptions: {
            authorizationParams: {
              // The attached token should target this audience
              audience: API_URL,

              // The attached token should have these scopes
              //  scope: 'read:current_user',
            },
          },
        },
      ],
    },
  },
};
