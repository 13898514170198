import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MyAuthService } from '../../services/auth/my-auth.service';
import { ObjetivoMenuEnum } from '../../constants/objetivo-menu';
import { Rol } from '../../constants/rol';
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  showDiv = true;
  isSticky = false;
  isFixed = false;
  menu = ObjetivoMenuEnum;
  rol = Rol;

  constructor(
    private router: Router,
    public authService: MyAuthService,
    private storageService: StorageService
  ) {
    this.router.events.subscribe((res) => {
      if (
        this.router.url.includes('compra') ||
        this.router.url.includes('meu-perfil') ||
        this.router.url.includes('admin')
      ) {
        this.isSticky = true;
        this.isFixed = true;
      } else {
        this.isSticky = false;
        this.isFixed = false;
      }
      // else {
      //   localStorage.removeItem('resumen_compra');
      // }
    });
  }

  handleLogout(): void {
    this.authService.logOut();
    this.storageService.clearAll();
  }

  ngOnInit(): void {}

  login() {
    this.authService.login(this.router.url);
  }

  goTo(id: string) {
    if (this.router.url == '/') {
      const element = document.getElementById(id);
      if (element) {
        const yOffset = -120;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    if (
      !this.router.url.includes('compra') &&
      !this.router.url.includes('meu-perfil') &&
      !this.router.url.includes('admin')
    )
      if (scrollTop > 90) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
  }
}
