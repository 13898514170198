import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-banner-tiempo',
  templateUrl: './banner-tiempo.component.html',
  styleUrls: ['./banner-tiempo.component.scss'],
})
export class BannerTiempoComponent {
  currentDate: Date;
  nextFriday: Date;
  nexFridayText: string;
  diaViernesAMostrar: string;
  demo: any;

  isMobile: boolean = false;
  isHome: boolean = false;
  @Output() closeEvent = new EventEmitter<void>();

  x = setInterval(() => {
    var now = new Date().getTime();
    var distance = new Date(this.nexFridayText).getTime() - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (days >= 1) {
      this.demo = days + ' dias ' + hours + ' : ' + minutes + ' : ' + seconds;
    } else {
      this.demo = hours + ' : ' + minutes + ' : ' + seconds;
    }
  });

  constructor(private router: Router) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url == '/') {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
      }
    });
  }

  ngOnInit() {
    this.currentDate = new Date();

    // Encuentra el siguiente viernes
    this.nextFriday = this.findNextFriday(this.currentDate);

    this.diaViernesAMostrar = this.formatDiaViernes(this.nextFriday);

    this.nexFridayText = this.formatDate(this.nextFriday);
    this.isMobile = window.innerWidth <= 768;
  }

  findNextFriday(date: Date): Date {
    const dayOfWeek = date.getDay();
    let daysUntilFriday = (5 - dayOfWeek + 7) % 7; // Días hasta el próximo viernes
    if (daysUntilFriday == 0) {
      daysUntilFriday = 7;
    }
    const nextFriday = new Date(date);
    nextFriday.setDate(date.getDate() + daysUntilFriday);
    return nextFriday;
  }

  formatDate(date: Date): string {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year} 12:00:00`;
  }

  formatDiaViernes(date: Date): string {
    const fecha = date.getDate() + '/' + (date.getMonth() + 1);
    return fecha;
  }

  close() {
    this.closeEvent.emit();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 768) this.isMobile = false;
    else this.isMobile = true;
  }
}
