<div class="header-section">
  <div class="container">
    <div class="row justify-content-between">
      <div class="w-auto align-self-center">
        <div class="header-social">
          <ul>
            <li>
              <a href="https://www.facebook.com/MsMoonjuicebar" target="_blank"
                ><i class="icofont-facebook"></i
              ></a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.google.com/maps/place/MS+Moon+Juice+Bar/@-23.1855938,-46.895175,17z/data=!3m1!4b1!4m6!3m5!1s0x94cf2707ad66c73f:0x4fcad227218ccb17!8m2!3d-23.1855938!4d-46.895175!16s%2Fg%2F11h0t0bh1f?entry=ttu"
                ><i class="icofont-google-map"></i
              ></a>
            </li>
            <li>
              <a target="_blank" href="#"><i class="icofont-whatsapp"></i></a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/msmoonjuicebar/?hl=pt-br"
                ><i class="icofont-instagram"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="w-auto d-flex">
        <p class="color-white">Compre comida saudável</p>
        <a
          href="https://ms-moon-juice-bar.goomer.app/"
          target="_blank"
          class="btn btn-transparent"
        >
          <img src="assets/img/icons/goomer.svg" class="icon-goomer"
        /></a>
        <a
          class="btn btn-transparent"
          href="https://www.ifood.com.br/delivery/jundiai-sp/ms-moon-juice-bar-vila-virginia/fd198444-6a31-4ab4-8976-5f7b54cd34b6"
          target="_blank"
        >
          <img src="assets/img/icons/ifood.png" class="icon-ifood"
        /></a>
        <a routerLink="compra/passo-1" class="btn btn-transparent"
          >Por assinatura</a
        >
      </div>
    </div>
  </div>
</div>
<app-banner-tiempo
  *ngIf="showDiv"
  (closeEvent)="showDiv = !showDiv"
></app-banner-tiempo>

<div
  class="navbar-area"
  [ngClass]="{ 'is-sticky': isSticky, 'is-absolute': isFixed }"
>
  <div class="mobile-nav">
    <a routerLink="/" class="logo">
      <img src="assets/img/logo.png" alt="logo" />
    </a>
  </div>

  <div class="main-nav">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" href="">
          <img src="assets/img/logo.png" class="logo-ff" alt="logo" />
        </a>

        <div
          class="collapse navbar-collapse mean-menu"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a
                (click)="goTo('quienes-somos')"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Quem somos</a
              >
            </li>
            <li class="nav-item">
              <a
                routerLink="nossas-lojas"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Nossas Lojas</a
              >
            </li>
            <li class="nav-item">
              <a
                href="javascript:void(0)"
                class="nav-link dropdown-toggle"
                (click)="goTo('menu')"
                >Nosso Menú<i class="icofont-rounded-right"></i
              ></a>

              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a
                    [routerLink]="'/linea/' + menu.MAS_MUSCULO"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Linea ++Muscle</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    [routerLink]="'/linea/' + menu.GANAR_MUSCULO"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Linea +Muscle</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    [routerLink]="'/linea/' + menu.MANTENER"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Linea Balance</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    [routerLink]="'/linea/' + menu.PERDER_PESO"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Linea Low Carb</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    [routerLink]="'/linea/' + menu.VEGANO"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Linea Plant Based</a
                  >
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <a
                (click)="goTo('como-funciona')"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Assinatura</a
              >
            </li>
            <li class="nav-item">
              <a
                routerLink="preg-frecuentes"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Perguntas frequentes</a
              >
            </li>
            <!-- <li class="nav-item">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSd4mNM9ZfWAIluFj0voJRhsqJJ23lKaeyZa4JZy9urE-Gajvg/viewform?usp=sf_link"
                class="nav-link"
                target="_blank"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Seja um Franqueado</a
              >
            </li> -->
            <li class="nav-item">
              <a class="nav-link dropdown-toggle"
                ><i class="fa-regular fa-circle-user"></i>
              </a>

              <ul
                class="dropdown-menu"
                *ngIf="authService.isAuthenticated$ | async; else noLogin"
              >
                <li class="nav-item" *ngIf="authService.rol == rol.ADMIN">
                  <a
                    routerLink="admin/comidas-preparar"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Comidas a preparar</a
                  >
                </li>
                <li class="nav-item" *ngIf="authService.rol == rol.ADMIN">
                  <a
                    routerLink="admin/historico-pedidos"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Pedidos históricos</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    routerLink="meu-perfil/pedidos"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Minhas compras</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    routerLink="meu-perfil"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Meu perfil</a
                  >
                </li>
                <li class="nav-item">
                  <a (click)="handleLogout()" class="nav-link">Sair</a>
                </li>
              </ul>
              <ng-template #noLogin>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a (click)="login()" class="nav-link">Login</a>
                  </li>
                </ul>
              </ng-template>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>
