<footer class="footer-area">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-lg-6 col-md-6">
        <div class="footer-widget">
          <div class="logo">
            <a routerLink="/">
              <img src="assets/img/logo.png" alt="logo" />
            </a>
          </div>
          <p>
            Ms Moon, desde 2019, transformando a alimentação com opções
            saudáveis e saborosas, sem glúten, sem lactose, veganas e
            vegetarianas, em um formato de refeições por assinatura para tornar
            a alimentação saudável uma rotina acessível e prática.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="footer-widget">
          <h3>Contate-nos</h3>
          <p class="find-text">
            Segunda a Sexta: 11h30 às 20h. Sábado: 12h às 17h.
          </p>

          <ul class="find-us">
            <li>
              <i class="icofont-location-pin"></i> R. Conrado Augusto Offa 485,
              Jundiai
            </li>
            <li>
              <i class="icofont-phone"></i>
              <a href="tel:+551144311039">+55 11 4431-1039</a>
            </li>
            <li>
              <i class="icofont-phone"></i>
              <a href="tel:+5511933101039">+55 11 93310-1039</a>
            </li>
            <li>
              <i class="icofont-ui-message"></i>
              <a href="contato@msmoon.com.br"> contato@msmoon.com.br</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-footer">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="footer-social">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/MsMoonjuicebar"
                  target="_blank"
                  ><i class="icofont-facebook"></i
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.google.com/maps/place/MS+Moon+Juice+Bar/@-23.1855938,-46.895175,17z/data=!3m1!4b1!4m6!3m5!1s0x94cf2707ad66c73f:0x4fcad227218ccb17!8m2!3d-23.1855938!4d-46.895175!16s%2Fg%2F11h0t0bh1f?entry=ttu"
                  ><i class="icofont-google-map"></i
                ></a>
              </li>
              <li>
                <a target="_blank" href="#"><i class="icofont-whatsapp"></i></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/msmoonjuicebar/"
                  target="_blank"
                  ><i class="icofont-instagram"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6">
          <a
            routerLink="politica-privacidad"
            class="nav-link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Política de privacidade e termos de uso</a
          >
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="top-btn">
  <i class="icofont-scroll-long-up"></i>
</div>
