import { Component, OnInit } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationCancel,
  NavigationEnd,
} from '@angular/router';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { filter } from 'rxjs/operators';
import { SharedService } from './components/shared/shared.service';
import { MyAuthService } from './components/services/auth/my-auth.service';
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AppComponent implements OnInit {
  location: any;
  routerSubscription: any;

  constructor(
    private router: Router,
    public sharedService: SharedService,
    private authService: MyAuthService
  ) {
    this.sharedService.showSpinnerChange.subscribe((x) => {
      if (x) $('.loader-content').fadeIn('slow');
      else $('.loader-content').fadeOut('slow');
    });
  }

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe((x) => {
      //Si recargo la pagina y esta logueado, me fijo el rol
      if (x) {
        this.authService.getRol();
      }
    });
    this.recallJsFuntions();
  }

  recallJsFuntions() {
    this.router.events.subscribe((event) => {
      // if (event instanceof NavigationStart) {
      //   $('.loader-content').fadeIn('slow');
      // }
    });
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel
        )
      )
      .subscribe((event) => {
        $.getScript('../assets/js/custom.js');
        // $('.loader-content').fadeOut('slow');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }
}
